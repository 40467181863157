<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">

    <v-row class="d-flex justify-center" no-gutters>
      <v-col cols='12' no-gutters>
        <div class="header1">
          <span class="text-center" style="font-weight: bold; font-size: 22px; padding-bottom: 10px;">ניהול רשימה
            לבנה</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols='auto' lg='6'>
        <div class="header2">

          <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
            class="search">
          </v-text-field>
          <div class="d-flex flex-wrap pa-3 ">
            <v-btn medium color="black" dark outlined class="mb-2 ms-2" style="max-width: 20px; border-color: #dddfe1;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="#9ea1a5" dark @click="openCreateDIalog()">
                    mdi-plus
                  </v-icon>
                </template>
                <span>הוסף חדש</span>
              </v-tooltip>
            </v-btn>
            <v-btn medium color="black" outlined dark class="mb-2 ms-2" style=" border-color: #dddfe1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="#9ea1a5" dark @click="downloadExcel()">
                    mdi-file-download
                  </v-icon>
                </template>
                <span>הורד לקובץ אקסל</span>
              </v-tooltip>
            </v-btn>
            <v-btn medium color="black" dark outlined class="mb-2 ms-2" style="max-width: 20px; border-color: #dddfe1;">
              <input type="file" style="display: none;" accept=".xls, .xlsx" ref="fileInput" @change="handleFileUpload">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="#9ea1a5" dark @click="$refs.fileInput.click()">
                    mdi-file-upload
                  </v-icon>
                </template>
                <span>ייבוא מאקסל</span>
              </v-tooltip>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols='auto' lg='6' v-if="$vuetify.breakpoint.mdAndUp">
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">

      <v-data-table id="table1" :headers="headers" :items="whiteList" :search="search" sort-by="index"
        :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }"
        style="background-color: #f3f6f9;">

        <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }">
          <span class="table-headers">{{ header.text }}</span>
        </template>

        <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
          <tr class="table-rows">
            <td class="table-cells">{{ index + 1 }}</td>
            <td class="table-cells">{{ item.phone }}</td>
            <td class="table-cells">{{ item.status }}</td>
            <td class="table-cells" style="color: #abafb3 !important">{{ item.created_at }}</td>
            <td class="table-cells" style="color: #abafb3 !important">{{ item.modified_at }}</td>
            <td class="table-cells">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#fe3153" width="106" max-width="100%" height="37" v-bind="attrs" v-on="on" rounded
                    class="mx-auto">
                    <span class="white--text cancle-button" style="">פעולות</span></v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="action in actionMenu" :key="action.id"
                    @click="action.id === 'edit' ? openEditDialog(item, index) : openDeleteDialog(item, index)">
                    <v-list-item-icon>
                      <v-icon>{{ action.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-row v-if="progressShow" class="my-5">
            <v-col class="d-flex justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
          <div class="ma-5">
            <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין
              עדיין נתונים </span>
          </div>
          <v-btn color="#0099cd" dark @click="refreshDetails()" style="margin-bottom: 10px;">
            Reset
          </v-btn>
        </template>

      </v-data-table>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      @codeAdded="newCodeSaved" @codeEdited="editedCodeSaved"> </AddEditDialog>

    <Alert-dialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle"
      :item="itemToDelete" @codeDeleted="codeDeleted"> </Alert-dialog>

    <ImportDialog v-model="fileImported" :importedSuccess="importedSuccess" :importedFailed="importedFailed"
      @codesImportedSuccess="codeImportedSuccessMessage"></ImportDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import TableToExcel from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/admin/whiteListPage/dialogs/AddEditDialog'
import AlertDialog from '@/components/admin/whiteListPage/dialogs/AlertDialog'
import ImportDialog from '@/components/admin/whiteListPage/dialogs/ImportFileDialog'
import utils from '@/util/utils'

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar,
    ImportDialog
  },
  data: () => ({
    isResponsive: false, // Initially set to false
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'מספר נייד', align: 'start', value: 'phone', },
      { text: 'סטטוס', value: 'status' },
      { text: 'נוצר בתאריך', value: 'created_at' },
      { text: 'עודכן בתאריך', value: 'modified_at' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    actionMenu: [
      { id: "edit", title: "ערוך רשומה", show: true, icon: "mdi-pencil" },
      { id: "delete", title: "מחק רשומה", show: true, icon: "mdi-delete" },
    ],
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    whiteList: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    isAuth: false,
    userName: "",
    email: "",
    fileImported: false,
    importedSuccess: [],
    importedFailed: [],
    search: "",
  }),

  methods: {

    async getWhiteListDetails() {
      try {
        this.codes = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/admin/white_list";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.whiteList = jsonObject;
          this.translateToHebrewAndDates();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get codes list: " + error, "red");
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    translateToHebrewAndDates() {
      this.whiteList = this.whiteList.map((obj) => {
        return {
          ...obj,
          status: obj.status === "new" ? "חדש" : obj.status === "used" ? "משומש" : obj.status,
          created_at: utils.getCurrentDate(obj.created_at),
          modified_at: utils.getCurrentDate(obj.modified_at),
        }
      });
    },
    downloadExcel() {
      // Create a copy of the headers with English text
      const englishHeaders = [
        { text: '#', value: "index", sortable: false },
        { text: 'phone', align: 'start', value: 'phone', },
        { text: 'status', value: 'status' },
        { text: 'created_at', value: 'created_at' },
        { text: 'modified_at', value: 'modified_at' },
        { text: 'פעולות', value: 'actions', sortable: false },
      ];
      // Clone the table
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);
      // Replace the table headers with the English headers in the cloned table
      const tableHeaders = clonedTable.getElementsByTagName('th');
      for (let i = 0; i < englishHeaders.length; i++) {
        tableHeaders[i].textContent = englishHeaders[i].text;
      }
      // Remove the first header
      tableHeaders[0].parentNode.removeChild(tableHeaders[0]);
      // Remove the last header
      tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);
      // Remove the first and last columns from the cloned table
      const tableRows = clonedTable.getElementsByTagName('tr');
      for (let i = 0; i < tableRows.length; i++) {
        const tableCells = tableRows[i].getElementsByTagName('td');
        if (tableCells.length > 0) {
          tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell
          tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
        }
      }
      // Download the Excel file from the cloned table
      TableToExcel.convert(clonedTable, {
        name: 'whiteList.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });
    },
    refreshPage() {
      location.reload();
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך מספר"
      this.editedItem = item
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemIndex = index;
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את המספר?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף מספר"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newCodeSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.whiteList.push(item);

    },
    editedCodeSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.whiteList.findIndex(obj => obj.id === item.id);

      let g = Object.assign(this.whiteList[index], item);

    },
    codeDeleted(item, text, color) {
      if (item === "") {
        this.showSnackBar(text, color)
        return
      }
      console.log(item);
      const index = this.whiteList.findIndex(obj => obj.id === item.id);
      this.whiteList.splice(index, 1);
      this.showSnackBar(text, color)
    },
    codeImportedSuccessMessage(text, color) {
      this.showSnackBar(text, color);
      this.getWhiteListDetails();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            for (let i = 0; i < jsonData.length; i++) {
              const row = jsonData[i];
              const { phone} = row;
              const rowData = {
                phone,
                status: "new",
              };

              const stringValue = phone ? phone.toString().trim() : '';
              if (!stringValue.startsWith("0")) {
                rowData.phone = "0" + rowData.phone
              }
              // check if code contain only 12 numeric chars and ther is no duplicate
              const existsInPhones = this.whiteList.some((phone) => phone.phone === stringValue);
              if (/^\d+$/.test(stringValue) && !existsInPhones) {
                this.whiteList.push(rowData);
                this.importedSuccess.push(rowData);
              } else {
                this.importedFailed.push(rowData);
              }
            }
            this.fileImported = true;
          } catch (error) {
            this.showSnackBar('Error parsing Excel file: ' + error, "red");
          }
        };
        reader.onerror = (e) => {
          this.fileImported = false;
          this.showSnackBar('Error reading file: ' + e.target.error, "red");
        };

        reader.readAsArrayBuffer(file);
      }
    },

  },
  mounted() {
    this.getWhiteListDetails();
  },
}
</script>
<style scoped>
.header1 {
  background-color: #fff;
  height: 89px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.header2 {
  min-height: 89px;
  display: flex;
}

.search {
  width: 332px !important;
  height: 49px;
  max-width: 100% !important;
  padding: 10px !important;
  padding-right: 10px !important;
  /* Space for the search icon */
  border: 1px solid #dddfe1 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  font-family: "circular-std-book" !important;
}

.table-headers {
  font-family: 'Circular-std-book' !important;
  font-size: 18px;
  font-weight: 100 !important;
}

.table-rows {
  background-color: #FFFFFF !important;
  height: 89px !important;
  box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
  border-radius: 3px !important;
}

.table-cells {
  font-family: 'Circular-std-book' !important;
  font-size: 16px !important;
  color: #6a707e;
}

.cancle-button {
  font-size: 16px;
  font-family: 'Circular-std-book' !important;
}
</style>
    