<template>
    <v-menu v-model="menu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <!-- <v-icon v-on="on" style="color: #6a6aec;">mdi-calendar</v-icon> -->
        <v-img src="@/assets/schedule.svg" v-on="on" style="margin-left: 10px !important; "></v-img>
      </template>
      <v-date-picker v-model="picker" color="#6a6aec" @click="menu = false" />
    </v-menu>
  </template>
  <script>
  export default {
    props: {
      value: {
        type: String,
        default: new Date().toISOString().substr(0, 10),
      },
    },
    data() {
      return {
        menu: false,
      };
    },
    computed: {
      picker: {
        get() {
          return this.value;
        },
        set(val) {
          this.menu = false;
          this.$emit("input", val);
        },
      },
    },
  };
  </script>