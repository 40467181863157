<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card>
      <v-card-title class="text-h5"> האם אתה בטוח שברצונך לבטל את הטעינה? </v-card-title>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete">ביטול</v-btn>
        <v-btn color="blue darken-1" text @click="actionConfirmed">אישור</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '../../../services/api.service.js'

export default {
  props: {
    itemIndex: Number,
    item: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    progressShow: false,
  }),

  methods: {
    actionConfirmed() {
      this.cancleActivated();
    },

    async cancleActivated() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const datesJSON = JSON.stringify(this.item);

        let api = process.env.VUE_APP_BASE_URL + "/seller/cancle_activated";
        const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
        this.progressShow = false;
        if (res.status === 401) {
          this.$emit("itemCanceled", "", "תקלה בביטול טעינה: אין לך הרשאה לביצוע פעולה ", "red");
        } else if (res.status === 400) {
          const jsonObject = await res.json();
          this.$emit("itemCanceled", "", " :שגיאה בביטול טעינה " + jsonObject.message, "red");
          this.openMode = false;
        } else if (res.status === 200) {
          this.$emit("itemCanceled", this.itemIndex, "הבקשה לביטול נשלחה לאישור", "green");
          this.openMode = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.$emit("itemCanceled", "", "תקלה בביטול טעינה " + error, "red");
      }
    },
    closeDelete() {
      this.openMode = false;
    }
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>

  