<template>
  <v-app style="background-color: #f3f6f9">
    <v-main>
    <nav-bar :balanceChanged="balance"></nav-bar>
    <MobileActivate @balanceChanged="balance += 1"/>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from '@/components/widgets/navBar.vue'
import MobileActivate from '@/components/seller/MobileActivate.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    NavBar,
    MobileActivate
  },
  data() {
    return {
      balance: 0,
    }
  },
  mounted() {
    document.title = 'Activate | ISRASIM'

    let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
  },
}
</script>
  