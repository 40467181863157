<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{ formTitle }}</v-card-title>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#6a6aec" text @click="closeDelete">ביטול</v-btn>
        <v-btn color="#6a6aec" text @click="actionConfirmed">אישור</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'

export default {
  props: {
    item: Object,
    formTitle: String,
    value: { type: Boolean, default: false },

  },
  data: () => ({
    progressShow: false,
  }),

  methods: {
    actionConfirmed() {
      this.deleteCode();
    },

    async deleteCode() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const codeJSON = JSON.stringify(this.item);

        let api = process.env.VUE_APP_BASE_URL + "/admin/delete_number_white_list";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", codeJSON, token));
        this.progressShow = false;
        if (res.status === 400) {
          this.$emit("codeDeleted", "", "תקלה במחיקת רשומה", "red");
        } else if (res.status === 403) {
          const jsonObject = await res.json();
          this.$emit("codeDeleted", "", " :שגיאה במחיקת רשומה" + jsonObject.message, "red");
          this.openMode = false;
        } else if (res.status === 200) {
          this.$emit("codeDeleted", this.item, "הרשומה נמחקה בהצלחה!", "green");
          this.openMode = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.$emit("codeDeleted", "", "תקלה במחיקת רשומה", "red");
      }
    },
    closeDelete() {
      this.openMode = false;
    }
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>
  
    