<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" outlined dense label="מספר נייד" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#6a6aec" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#6a6aec" text @click="formTitle === 'ערוך מספר' ? saveEditedCode() : saveNewCode()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="4000">
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },

    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            id: "",
            phone: "",
        },
    }),
    methods: {
        async saveNewCode() {
            if (this.$refs.form.validate()) {
                const datesJSON = JSON.stringify(this.itemEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/add_number_white_list";
                    const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.$emit("codeAdded", "", " :שגיאה בהוספת מספר" + jsonObject.details.errors[0].message, "red");
                    } else if (res.status === 403) {
                        this.$emit("codeAdded", "", " :שגיאה בהוספת מספר" + jsonObject.message, "red");
                        this.openMode = false;
                    } else if (res.status === 200) {
                        this.itemEdited.id = jsonObject.id;
                        this.$emit("codeAdded", this.itemEdited, "הרשומה נוספה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved code: " + error, "red");
                    this.$emit("codeAdded", "", "Error saved code: " + error, "red");
                }
            }
        },
        async saveEditedCode() {
            if (this.$refs.form.validate()) {
                const datesJSON = JSON.stringify(this.itemEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/update_number_white_list";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
                    this.progressShow = false;
                    if (res.status === 400) {
                        const jsonObject = await res.json();
                        this.$emit("codeEdited", "", "error: " + jsonObject.details.errors[0].message, "red");
                    } else if (res.status === 403) {
                        const jsonObject = await res.json();
                        this.$emit("codeEdited", "", " :שגיאה  בעריכת מספר" + jsonObject.message, "red");
                        this.openMode = false;
                    } else if (res.status === 200) {
                        this.$emit("codeEdited", this.itemEdited, "הרשומה עודכנה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved list: " + error, "red");
                    this.$emit("codeEdited", "", "Error edit date: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך מספר") {
            this.itemEdited = { ...this.itemToEdit };
        }
    },
}
</script>
<style scoped>
.v-text-field>.v-input__control>.v-input__slot:before {
  width: 100% !important
}
</style>
  