
class Services {
  constructor() {
  }

  // RETURN A NICE DATE STRING
  static getCurrentDate(date) {

    let currentDate;
    currentDate = new Date();
    if (date) {
      currentDate = new Date(date);
    }
    let formattedDate;
    let formattedTime;
    formattedDate = `${String(currentDate.getDate()).padStart(2, "0")}/${String(currentDate.getMonth() + 1).padStart(2, "0")}/${currentDate.getFullYear()}`;
    formattedTime = `${String(currentDate.getHours()).padStart(2, "0")}:${String(currentDate.getMinutes()).padStart(2, "0")}:${String(currentDate.getSeconds()).padStart(2, "0")}`;
    return formattedDate + "," + formattedTime
  }



  // RETURN A DATE OBJECT FOR SORTING
  static getoBJDate(dateString) {

    // Split the string into date and time parts
    let [datePart, timePart] = dateString.split(",");

    // Split the date part into day, month, and year
    let [day, month, year] = datePart.split("/");

    // Rearrange the date parts into the expected format
    let modifiedDateString = `${month}/${day}/${year} ${timePart}`;

    // Create the Date object
    let dateObject = new Date(modifiedDateString);
    console.log(dateObject);
    return dateObject;
  }

  // static getoBJDate2(dateString) {

  //   let currentDate;
  //   currentDate = new Date();
  //   if (dateString) {
  //     currentDate = new Date(dateString);
  //   }
  //   let day = currentDate.getDate();
  //   let month = currentDate.getMonth() + 1;
  //   let year = currentDate.getFullYear();
  //   let hours = currentDate.getHours()
  //   let minutes =currentDate.getMinutes()
  //   let second =currentDate.getSeconds()
  //   return `${day}/${month}/${year} ${hours}:${minutes}:${second}`;
  // }

}

export default Services;

