<template>
  <v-app style="background-color: #f3f6f9">
    <v-main>
      <nav-bar></nav-bar>
      <ChartsStatisticMonth :dataMonth="filteredCounMonth" />
      <ChartsStatisticDay :dataDays="filteredCounDays" />
      <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from '@/components/widgets/navBar.vue'
import Auth from '@/services/auth.service.js'
import ChartsStatisticMonth from '@/components/common/ChartsStatisticMonth.vue'
import ChartsStatisticDay from '@/components/common/ChartsStatisticDay.vue'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  components: {
    NavBar,
    SnackBar,
    ChartsStatisticMonth,
    ChartsStatisticDay,
  },
  data() {
    return {
      data: [{}],
      dataDays: [{}],
      dataMonth: [{}],
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
    };
  },
  methods: {
    async getStatisticsDayMonth() {
      try {
        this.data = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + "/seller/get_statistic";
        const res = await fetch(api, ApiServices.requestOptions("GET", null, token));
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          const jsonObject = await res.json();
          this.data = jsonObject;
          this.filterdData();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get codes list: " + error, "red");
      }
    },
    filterdData() {

      // Reset dataDays and dataMonth arrays
      this.dataDays = [];
      this.dataMonth = [];

      // Get the current date
      const currentDate = new Date();

      // Get the number of days in the current month
      const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
      // Initialize dataDays array with 31 days
      for (let i = 1; i <= daysInMonth; i++) {
        this.dataDays.push({ day: i, count: 0 });
      }

      // Initialize dataMonth array with 12 months
      for (let i = 1; i <= 12; i++) {
        this.dataMonth.push({ month: i, count: 0 });
      }

      // Filter this.data values by created_at date
      this.data.forEach(item => {
        const itemDate = new Date(item.created_at);
        const itemDay = itemDate.getDate();
        const itemMonth = itemDate.getMonth() + 1;

        // Check if the item was created in the current month
        if (itemMonth === currentDate.getMonth() + 1 && itemDate.getFullYear() === currentDate.getFullYear()) {
          // Update dataDays array
          if (itemDay >= 1 && itemDay <= daysInMonth) {
            this.dataDays[itemDay - 1].count++;
          }
        }
        // Check if the item was created in the current year
        if (itemDate.getFullYear() === currentDate.getFullYear()) {
          // Update dataMonth array
          if (itemMonth >= 1 && itemMonth <= 12) {
            this.dataMonth[itemMonth - 1].count++;
          }
        }

      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    }
  },
  computed: {
    filteredCounMonth() {
      return this.dataMonth.map(item => item.count);
    },
    filteredCounDays() {
      return this.dataDays.map(item => item.count);
    },
  },
  mounted() {
    document.title = 'Dashboard | ISRASIM'

    let auth = Auth.checkAuth();
    if (!auth) {
      return this.$router.replace({ name: "login" })
    }
    this.getStatisticsDayMonth();
  },
}
</script>
<style>
/* .container1 {
      width: 1000px; 
      margin: 0 auto; */
/* display: flex; */
/* flex-direction: column; */
/* justify-content: center; */
/* align-items: center; */
/* height: 100vh;  */
/* } */
</style>