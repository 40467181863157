<template>
     <v-app style="background-color: #f3f6f9">
    <v-main>
      <nav-bar></nav-bar>
      <Refund/>
    </v-main>
  </v-app>
  </template>
  <script>
  import NavBar from '@/components/widgets/navBar.vue'
  import Refund from '@/components/admin/refundsPage/Refund.vue'
  import Auth from '@/services/auth.service.js'
  
  export default {
    components: {
      NavBar,
      Refund
    },
    mounted() {
      document.title = 'Refunds | ISRASIM'

      let auth = Auth.checkAuth();
        if (!auth) {
          this.$router.replace({ name: "login" })
        }
    },
  }
  </script>
    