<template>
  <v-container style="margin-top: 90px; margin-bottom: 70px;">
    <v-row class=" d-flex justify-center">
      <v-col cols="12" md="4" v-if="plans.length > 0" v-for="(item, index) in plans" :key="item.id">
        <div style="width: 496px; height: 416px; max-width: 100%;">
          <!-- <v-img alt="Vuetify Logo" src="@/assets/Ad.png"  transition="scale-transition"  /> -->
          <v-row style="background-color: #0099cd; height: 120px;">
            <v-col cols="6" class="text-center" style="border-left: 1px solid black;">
              <span style="font-size: 25px; color: white; font-weight: bold; display: block;">100GB</span>
              <span style="font-size: 15px; color: white; font-weight: bold;  display: block;">3000 דקות שיחה</span>
              <span style="font-size: 15px; color: white; font-weight: bold;  display: block;">1500 הודעות SMS</span>
            </v-col>
            <v-col cols="6" class="d-flex justify-center align-center ad-header">
              <span style="font-size: 51px; color: white;">{{ item.price }}ש"ח</span>
            </v-col>
          </v-row>
          <v-row style="background-color: #FFFFFF; height: 306px;">
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-center" style="padding-top: 20px; padding-bottom: 0px;">
                <span style="font-size: 31px; color: #464A53; ">Free browsing of apps</span>
              </v-col>
              <v-col cols="12" class="d-flex justify-center" style="padding-top: 0px; padding-bottom: 0px;">
                <span style="font-size: 31px; color: #464A53;">גלישה חופשית באפליקציות:</span>
              </v-col>
            </v-row>
            <div style="width: 416; max-width: 100%; padding-left: 10%; padding-right: 10%;">
              <v-img alt="Vuetify Logo" src="@/assets/group-276@2x.png" contain transition="scale-transition" />
            </div>
            <div style="padding-left: 10%; padding-right: 10%; ">
              <div style="border-top: 1px solid #e4e4e4; padding-bottom: 0px !important;"></div>
            </div>
            <v-row no-gutters>
              <span class="text-center" style="padding-bottom: 0px !important;">החבילה ל-30 יום</span>
            </v-row>
            <v-row>
              <div style="margin: 10px;" class="text-center">
                <v-btn color="#0099cd" rounded :outlined="clickedButtonIndex !== index"
                  :dark="clickedButtonIndex === index" style="width: 150px;" @click="buttonClicked(index, item.id)">
                  לרכישה
                </v-btn>
              </div>
            </v-row>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-10 d-flex justify-center">
      <v-card style="width: 757px; max-width: 100%; padding-bottom: 40px;">
        <div class="title-style">
          <span> טעינה דיגיטלית</span>
        </div>
        <div style="padding-left: 10%; padding-right: 10%; ">
          <div id="divider" style="border-top: 1px solid #e4e4e4; padding-bottom: 0px !important;"></div>
        </div>
        <div style="  margin-top: 30px; font-size: 16px;">
          <span> {{ title }}</span>
        </div>
        <v-card-text class="phone-number-inputs">
          <v-text-field v-for="(char, index) in phoneNumber.slice(0, 7)" :key="index" v-model="phoneNumber[index]"
            @input="moveFocus(index)" :ref="'textfield' + index" maxlength="1" hide-details
            class="phone-number-field centered-input" @keypress="validateNumericInput"
            :type="isPhoneSize ? 'number' : 'text'">
          </v-text-field>
          <div class="centered-span">
            <span>-</span>
          </div> <v-text-field v-for="(char, index) in phoneNumber.slice(7)" :key="index + 7"
            v-model="phoneNumber[index + 7]" @input="moveFocus(index + 7)" :ref="'textfield' + (index + 7)" maxlength="1"
            hide-details class="phone-number-field centered-input" @keypress="validateNumericInput"
            :type="isPhoneSize ? 'number' : 'text'"></v-text-field>
        </v-card-text>
        <div class="mb-3" style="text-align: center; margin-top: 15px;">
          <v-btn @click="checkValidate()" color="#0099cd" width="243" max-width="100%" height="48" rounded
            class="mx-auto">
            <span class="white--text" style="font-weight: bold; font-size: 18px; ">המשך</span>
          </v-btn>
        </div>
        <v-row v-if="progressShow" class="my-5">
          <v-col class="d-flex justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
        <div v-if="showErrorMessage" style="text-align: center;">
          <span style="color: red; font-weight: 700;">{{ errorMessageText }}</span>
        </div>
      </v-card>
    </v-row>
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import SharedFunc from '@/shared/sharedFunc'

export default {
  components: {
    SnackBar
  },
  data() {
    return {
      phoneNumber: ['', '', '', '', '', '', '', '', '', ''],
      title: 'הכנס מספר פלאפון משמאל לימין',
      phoneInserted: "",
      phoneCHeck: '',
      showErrorMessage: false,
      errorMessageText: '',
      progressShow: false,
      plans: [],
      selectedPlan: "",
      clickedButtonIndex: null,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      isPhoneSize: false
    };
  },
  methods: {
    moveFocus(index) {
      if (index > 0) { // Change condition to index > 0
        this.$refs['textfield' + (index - 1)][0].focus(); // Change index + 1 to index - 1
      }
    },
    // onOtpInput(value) {
    //   // The 'value' parameter contains the input value from v-otp-input
    //   this.phoneCHeck = value;
    // },
    validateNumericInput(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    clearFields() {
      this.$nextTick(() => {
        const lastIndex = this.phoneNumber.length - 1;
        this.$refs['textfield' + lastIndex][0].focus();
        // setTimeout(() => {
        this.phoneNumber = this.phoneNumber.map(() => '');
        // }, 100); 
      });
    },
    checkValidate() {

      this.showErrorMessage = true;
      if (!this.selectedPlan) {
        return this.errorMessageText = "נא לבחור חבילה"
      }

      if (!this.phoneInserted) {
        this.errorMessageText = "הכנס מספר פלאפון"
      } else if (!this.phoneCHeck) {
        this.errorMessageText = "הכנס מספר פלאפון שוב"
      } else if (this.phoneInserted !== this.phoneCHeck) {
        this.errorMessageText = "מספרי הטלפון אינם תואמים"
      } else {
        this.activate();
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async activate() {

      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const phoneJSON = JSON.stringify({ mobileNumber: this.phoneCHeck, plan_id: this.selectedPlan.id });
        let api = process.env.VUE_APP_BASE_URL + "/seller/activate_mobile";
        const res = await fetch(api, ApiServices.requestOptions("POST", phoneJSON, token));
        this.progressShow = false;
        if (res.status === 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאת טעינה: " + jsonObject.message, "red");
        } if (res.status === 403) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          localStorage.setItem('balance', localStorage.getItem("balance") - 79);
          this.$emit("balanceChanged");
          this.showSnackBar("הטעינה בוצעה בהצלחה!", "green");
          this.clearFields();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error activate phone list: " + error, "red");
      }
    },
    async getSellerPlans() {
       this.plans = await SharedFunc.getPlans();
    },
    buttonClicked(index, itemId) {
      // Update the clicked button's index
      this.clickedButtonIndex = index;
      // Now call your planChosen function or whatever you need to do when a button is clicked
      this.planChosed(itemId);
    },
    planChosed(planId) {
      this.selectedPlan = this.plans.find(plan => plan.id === planId);
    },
    checkScreenSize() {
      this.isPhoneSize = window.innerWidth <= 480; // Adjust the breakpoint as needed
    },

  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch: {
    phoneNumber() {
      const isFilled = this.phoneNumber.every((char) => char !== '');
      if (isFilled) {
        if (!this.phoneInserted) {
          this.title = 'הכנס מספר פלאפון שוב';
          this.phoneInserted = this.phoneNumber.join('');
          this.phoneInserted = this.phoneInserted.split('').reverse().join('');
          this.clearFields();
        } else if (!this.phoneCHeck) {
          this.phoneCHeck = this.phoneNumber.join('');
          this.phoneCHeck = this.phoneCHeck.split('').reverse().join('');
          if (this.phoneInserted !== this.phoneCHeck) {
            this.errorMessageText = "מספרי הטלפון אינם תואמים"
            this.showErrorMessage = true;
            this.clearFields();
            this.title = 'הכנס מספר פלאפון';
            this.phoneInserted = "";
            this.phoneCHeck = "";
          }
        }
      }
    },
  },
  mounted() {
    this.getSellerPlans();
    this.checkScreenSize();
    // Listen for screen size changes
    window.addEventListener('resize', this.checkScreenSize);
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-right: 5px;
  margin-left: 5px;
}

.phone-number-inputs {
  display: flex;
  justify-content: center;
}

.phone-number-field {
  width: 60px;
  max-width: 100%;
  height: 52px;
  font-size: x-large;
  margin: 5px !important;
  background-color: rgba(106, 112, 126, 0.1) !important;
  border: 1px solid #dddfe1;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.title-style {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-top: 30px;
}

.ad-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  max-width: 100% !important;

}

.centered-input input {
  text-align: center
}

.centered-span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  /* Add margin for spacing */
  font-size: x-large;
}
</style>
