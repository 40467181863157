import ApiServices from '@/services/api.service'

class sharedFunc {
    constructor() {
    }
  
   static async getPlans() {
        let token = localStorage.getItem("token");
        try {
            let api = process.env.VUE_APP_BASE_URL + "/plans/get_plans";
            const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
            const jsonObject = await res.json();
            if (res.status === 200) {
               return jsonObject;
            }
        } catch (error) {
          console.log(error);
          return [];
        }
    }
    
  
  }
  
  export default sharedFunc;
  
  