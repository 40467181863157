<template >
    <v-container style="margin-top: 70px; margin-bottom: 70px;">

        <v-row class="d-flex justify-center" no-gutters>
            <v-col cols='12' no-gutters>
                <div class="header1">
                    <span class="text-center" style="font-weight: bold; font-size: 22px; padding-bottom: 10px;">בקשות
                        ביטולים</span>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
            <v-col cols='auto' lg='6'>
                <div class="header2">

                    <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                        class="search">
                    </v-text-field>
                    <div class="d-flex flex-wrap pa-3 ">
                        <v-btn medium color="black" outlined dark class="mb-2 ms-2" style=" border-color: #dddfe1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="#9ea1a5" dark @click="downloadExcel()">
                                        mdi-file-download
                                    </v-icon>
                                </template>
                                <span>הורד לקובץ אקסל</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols='auto' lg='6' v-if="$vuetify.breakpoint.mdAndUp">
            </v-col>
        </v-row>

        <v-row class="d-flex justify-center">

            <v-data-table id="table1" :headers="headers" :items="refunds" :search="search" sort-by="created_at" sort-desc
                :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }"
                style="background-color: #f3f6f9;">

                <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }">
                    <span class="table-headers">{{ header.text }}</span>
                </template>

                <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                    <tr class="table-rows">
                        <td class="table-cells">{{ index + 1 }}</td>
                        <td class="table-cells">{{ item.code_active }}</td>
                        <td class="table-cells">{{ item.mobile_activated }}</td>
                        <td class="table-cells">{{ item.seller_owner }}</td>
                        <td class="table-cells" :class="[item.status === 'בוטל' ? 'header-cell-red' :
                            '', item.status === 'בבדיקה' ? 'header-cell-orange' : '']">{{ item.status }}</td>
                        <td class="table-cells" style="color: #abafb3 !important">{{ item.modified_at }}</td>
                        <td class="table-cells">
                            <v-menu bottom right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="#fe3153" width="106" max-width="100%" height="37" v-bind="attrs" v-on="on"
                                        rounded class="mx-auto">
                                        <span class="white--text cancle-button" style="">פעולות</span></v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="action in actionMenu" :key="action.id"
                                        @click="action.id === 'confirm' ? openAlertDialog('confirmed', item, index) : openAlertDialog('rejected', item, index)">
                                        <v-list-item-icon>
                                            <v-icon>{{ action.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{ action.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>

                <template v-slot:no-data>
                    <v-row v-if="progressShow" class="my-5">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                        </v-col>
                    </v-row>
                    <div class="ma-5">
                        <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין
                            בקשות
                            לביטולים</span>
                    </div>
                    <v-btn color="#0099cd" dark @click="getCancleRequests()" style="margin-bottom: 10px;">
                        Reset
                    </v-btn>
                </template>
            </v-data-table>
        </v-row>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <AlertDialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle" :item="itemToDelete"
            @refundsUpdated="refundsUpdated"> </AlertDialog>

    </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import TableToExcel from "@linways/table-to-excel";
import utils from '@/util/utils'
import AlertDialog from '@/components/admin/refundsPage/AlertDialog'

export default {
    components: {
        SnackBar,
        AlertDialog
    },
    data: () => ({
        isResponsive: false, // Initially set to false
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'קוד טעינה', align: 'start', value: 'code_active', },
            { text: 'טלפון שהופעל', value: 'mobile_activated' },
            { text: 'משווק', value: 'seller_owner' },
            { text: 'סטטוס', value: 'status' },
            { text: 'נוצר בתאריך', value: 'modified_at' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
        actionMenu: [
            { id: "confirm", title: "אישור בקשה", show: true, icon: "mdi-check-circle" },
            { id: "reject", title: "דחיית בקשה", show: true, icon: "mdi-close-circle" },
        ],
        refunds: [],
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemToDelete: {},
        itemIndex: 0,
        alertDialog: false,
        formTitle: "",
        itemToDelete: {},
        userName: "",
        email: "",
        sellerName: "",
        search: "",
    }),
    computed: {

    },
    methods: {

        async getCancleRequests() {
            try {
                this.refunds = [];
                this.progressShow = true;
                let token = localStorage.getItem("token");

                let api = process.env.VUE_APP_BASE_URL + "/admin/get_refunds_list";
                const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.refunds = jsonObject;
                    this.translateToHebrewAndDates();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get refunds list: " + error, "red");
            }
        },

        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        translateToHebrewAndDates() {
            this.refunds = this.refunds.map((report) => {
                return {
                    ...report,
                    status: report.status === "success" ? "הצליח" : report.status === "canceled" ? "בוטל" : report.status === "in progress" ? "בבדיקה" : report.status,
                    modified_at: utils.getCurrentDate(report.modified_at),
                }
            });
        },
        openAlertDialog(type, item, index) {
            if (type === "confirmed") {
                this.formTitle = "האם אתה בטוח שברצונך לאשר את הבקשה?"
            } else {
                this.formTitle = "האם אתה בטוח שברצונך לדחות את הבקשה?"
            }
            this.itemToDelete = item;
            this.itemToDelete.itemIndex = index
            this.alertDialog = true;
            console.log(this.itemToDelete);
        },
        refundsUpdated(item, text, color) {
            // let g = Object.assign(this.refunds[item.itemIndex], item);
            // this.refunds[item.itemIndex].status = "הצליח"
            this.showSnackBar(text, color);
            this.getCancleRequests();
        },
        downloadExcel() {

            // Download the Excel file from the cloned table
            TableToExcel.convert(document.getElementById("table1"), {
                name: 'reportsList.xlsx',
                sheet: {
                    name: 'Sheet 1',
                },
            });
        },
    },
    mounted() {
        this.sellerName = this.$route.params.name;
        this.getCancleRequests();
    },
}
</script>
<style scoped>
.header1 {
    background-color: #fff;
    height: 89px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.header2 {
    min-height: 89px;
    display: flex;
}

.search {
    width: 332px !important;
    height: 49px;
    max-width: 100% !important;
    padding: 10px !important;
    padding-right: 10px !important;
    /* Space for the search icon */
    border: 1px solid #dddfe1 !important;
    box-sizing: border-box !important;
    font-size: 15px !important;
    font-family: "circular-std-book" !important;
}

.table-headers {
    font-family: 'Circular-std-book' !important;
    font-size: 18px;
    font-weight: 100 !important;
}

.table-rows {
    background-color: #FFFFFF !important;
    height: 89px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Circular-std-book' !important;
    font-size: 16px !important;
    color: #6a707e;
}

.cancle-button {
    font-size: 16px;
    font-family: 'Circular-std-book' !important;
}

.header-cell-red {
    color: rgb(202, 4, 4) !important;
}

.header-cell-orange {
    color: rgb(214, 141, 4) !important;
}
</style>
    