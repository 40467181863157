<template>
    <div>

        <v-card class="mx-auto" style="margin-top: 100px; margin-bottom: 100px; width: 80%;">
            <div class="title-style">
                <span>אזור אישי</span>
            </div>
            <div style="padding-left: 10%; padding-right: 10%; ">
                <div id="divider" style="border-top: 1px solid #e4e4e4; padding-bottom: 0px !important;"></div>
            </div>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">שם*</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.name" style="width: 70%;" outlined dense label="שם"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">שם לחשבונית</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.nameInvoice" style="width: 70%;" outlined
                                    label="שם לחשבונית" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">כתובת*</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.address" style="width: 70%;" outlined
                                    :rules="[v => !!v || 'שדה חובה']" required label="כתובת" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">טלפון*</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.phone" style="width: 70%;" outlined
                                    :rules="[v => !!v || 'שדה חובה']" required label="טלפון" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">אימייל*</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.email" style="width: 70%;" outlined
                                    :rules="[v => !!v || 'שדה חובה']" required label="אימייל" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">קבוצה</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.role" disabled style="width: 70%;" outlined
                                    label="קבוצה" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">הרשאות</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.permission" disabled style="width: 70%;" outlined
                                    required label="הרשאות" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3">
                                <div style="text-align: end; margin-left: 50px;">
                                    <span class="fontTitle">API token</span>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="9">
                                <v-text-field v-model="userDetailsEdited.api_token" disabled style="width: 70%;" outlined
                                    label="API token" dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" sm="3"></v-col>
                            <v-col cols="auto" sm="9">
                                <div class="mb-3">
                                    <v-btn @click="saveChanges()" color="#0099cd" width="167" max-width="100%" height="48"
                                        rounded>
                                        <span class="white--text" style="font-weight: bold; font-size: 18px; ">עדכן</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        SnackBar
    },
    props: {
        userDetails: Object
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            userDetailsEdited: {
                name: "aa",
                nameInvoice: "bb",
                address: "cc",
                phone: "dd",
                email: "dd",
            }

        };
    },
    methods: {
        async getUserDetails() {
            try {
                this.userDetailsEdited = {};
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/seller/get_seller_details";
                const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.userDetailsEdited = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get codes list: " + error, "red");
            }
        },
        async saveChanges() {

            if (this.$refs.form.validate()) {
                const editedJSON = JSON.stringify(this.userDetailsEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/seller/update_seller_details";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", editedJSON, token));
                    this.progressShow = false;
                    if (res.status === 403) {
                        const jsonObject = await res.json();
                        this.showSnackBar("Error saved details: " + jsonObject.message, "red");
                    }
                    if (res.status === 400) {
                        const jsonObject = await res.json();
                        this.showSnackBar("Error saved details: " + jsonObject.errors[0].message, "red");
                    } else if (res.status === 200) {
                        this.showSnackBar("הנתונים עודכנו בהצלחה", "green");
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved details: " + error, "red");
                }
            }
        },
        cancelChanges() {
            this.$emit("Cancle");
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.getUserDetails();
    },
};
</script>
<style>
.title-style {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    padding-top: 40px !important;
}

/* .centered-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.fontLabel {
    font-family: "circular-std-book" !important;
    font-size: 16px;
    color: #abafb3;
}

.fontTitle {
    font-family: "circular-std-book" !important;
    color: #464a53;
    font-size: 18px;
}
</style>