import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import RessetPassView from '../views/ResetPasswordView.vue'
import SellerStatisticsView from '../views/seller/SellerStatisticsView.vue'
import SellerActivateView from '../views/seller/ActivateSimView.vue'
import SellerReportsView from '../views/seller/ReportsView.vue'
import SellerAccountView from '../views/seller/AccountView.vue'
import AdminUsersListView from '../views/admin/UsersListView.vue'
import AdminStatisticView from '../views/admin/StatisticView.vue'
import AdminCodesListView from '../views/admin/CodesListView.vue'
import AdminWhiteListView from '../views/admin/WhiteListView.vue'
// import AdminSellersReportView from '../views/admin/SingleSellerReportsView.vue'
import AdminRefundView from '../views/admin/RefundView.vue'

//import Auth from '@/services/auth.service.js'

Vue.use(VueRouter)




const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/resset-password/:token',
    name: 'ressetPass',
    component: RessetPassView,
  },
  {
    path: '/seller',
    name: 'seller',
    component: SellerStatisticsView,
  },
  {
    path: '/seller_reports',
    name: 'seller_reports',
    component: SellerReportsView,
  },
  {
    path: '/seller_activate',
    name: 'seller_activate',
    component: SellerActivateView,
  },
  {
    path: '/seller_account',
    name: 'seller_account',
    component: SellerAccountView,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminStatisticView,
  },
  {
    path: '/admin_users_list',
    name: 'admin_statistic',
    component: AdminUsersListView,
  },
  {
    path: '/admin_codes',
    name: 'admin_codes',
    component: AdminCodesListView,
  },
  {
    path: '/admin_white_list',
    name: 'admin_white_list',
    component: AdminWhiteListView,
  },
  // {
  //   path: '/admin_seller_reports/:id',
  //   name: 'admin_seller_reports',
  //   component: AdminSellersReportView,
  // },
  {
    path: '/admin_refund',
    name: 'admin_refund',
    component: AdminRefundView,
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
