<template>
  <v-app style="background-color: #f3f6f9">
    <v-main>
    <nav-bar></nav-bar>
    <SellerAccountEdit />
  </v-main>
  </v-app>
</template>
<script>
import SellerAccountEdit from '@/components/seller/SellerAccountEdit.vue'
import NavBar from '@/components/widgets/navBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    SellerAccountEdit,
    NavBar
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
    document.title = 'My-Account | ISRASIM'

    let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
  },
}
</script>
  