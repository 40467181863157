<template>
    <v-dialog v-model="openMode" persistent max-width="500px">
        <v-card style="padding: 20px;">
            <div class="d-flex justify-center ">
                <span> {{ importedSuccess.length }} מספרים נוספו בהצלחה</span>
            </div>
            <div class="d-flex justify-center">
                <span>{{ importedFailed.length }} מספרים נכשלו</span>
            </div>
            <div class="d-flex justify-center">
                <div v-if="progressSave" style="margin-right: 10px;">
                    <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#6a6aec" text @click="refreshPage">בטל ייבוא</v-btn>
                <v-btn color="#6a6aec" text @click="actionConfirmed">שמור נתונים מאקסל</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'

export default {
    props: {
        importedSuccess: Array,
        importedFailed: Array,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        progressSave: false,
    }),

    methods: {
        actionConfirmed() {
            this.saveImported();
        },
        refreshPage() {
            location.reload();
        },
        async saveImported() {

            const filteredCodesJSON = JSON.stringify(this.importedSuccess);
            this.progressSave = true;
            let token = localStorage.getItem("token");

            try {
                let api = process.env.VUE_APP_BASE_URL + "/admin/add_number_white_list_imported";
                const res = await fetch(api, ApiServices.requestOptions("POST", filteredCodesJSON, token));
                this.progressSave = false;
                if (res.status === 400) {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                } else if (res.status === 403) {
                    const jsonObject = await res.json();
                    this.$emit("codesImportedSuccess", " :שגיאה בהוספת מספר" + jsonObject.message, "red");
                    this.openMode = false;
                } else if (res.status === 201) {
                    this.openMode = false;
                    this.$emit("codesImportedSuccess", "אין נתונים לשמירה", "red");
                } else if (res.status === 200) {
                    this.openMode = false;
                    this.$emit("codesImportedSuccess", "הנתונים נשמרו בהצלחה!", "green");
                }
            } catch (error) {
                this.progressSave = false;
                this.$emit("codesImportedSuccess", "Error saved list: " + error, "red");
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
  
    