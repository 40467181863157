<template>
    <div class="chart-container">
        <div class="chart-wrapper">
            <canvas id="myChartPie" class="responsive"></canvas>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>
     
<script>
import Chart from 'chart.js/auto';
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        SnackBar
    },
    data: () => ({
        dataCodes: [],
        filteredData: null,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        chartInstance: null // Keep track of the chart instance
    }),
    methods: {
        createChart() {
            const ctx = document.getElementById('myChartPie');

            this.chartInstance = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: ['Used','New'],
                    datasets: [{
                        label: 'Codes avaliable',
                         data: [1,1],
                        //  data: this.filteredData ? this.filteredData : [1,1],
                        hoverOffset: 0,
                        backgroundColor: ['#6a6aec', 'grey'], // Set the custom colors here
                    }],

                },
                options: {
                    radius: "80%",
                }
            });
        },
        async getCodes() {
            try {
                this.data = [];
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/admin/get_statistic_codes";
                const res = await fetch(api, ApiServices.requestOptions("GET", null, token));
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    const jsonObject = await res.json();
                    this.dataCodes = jsonObject;
                    this.filterData();
                }
            } catch (error) {
                this.showSnackBar("Error get codes list: " + error, "red");
            }
        },
        filterData() {
            const usedCodes = [];
            const newCodes = [];

            for (const code of this.dataCodes) {
                if (code.status === 'used') {
                    usedCodes.push(code);
                } else if (code.status === 'new') {
                    newCodes.push(code);
                }
            }
            this.filteredData = [usedCodes.length, newCodes.length]
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        }
    },
    watch: {
        filteredData: {
            handler(newData) {
                if (this.chartInstance) {
                    // Update the chart data
                    this.chartInstance.data.datasets[0].data = newData;
                    this.chartInstance.update();
                }
            },
            immediate: true // Update the chart when the component is mounted
        }
    },
    mounted() {
        this.createChart();
        this.getCodes()
    },
}
</script>
     
<style >
.chart-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.chart-wrapper {
    background-color: white;
    padding: 10px;
    width: 100%;
    max-width: 1000px;
    height: 500px;
    display: flex;
     justify-content: center;
}

.responsive {
    width: 100%;
    height: 300px;
}

@media (min-width: 568px) {
    .responsive {
        height: 500px;
    }
}</style>