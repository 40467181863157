<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center" no-gutters>
      <v-col cols='12' no-gutters>
        <div class="header1">
          <span class="text-center" style="font-weight: bold; font-size: 22px; padding-bottom: 10px;">ניהול משתמשים</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols='auto' lg='6'>
        <div class="header2">

          <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
            class="search">
          </v-text-field>
          <div class="d-flex flex-wrap pa-3 ">
            <v-btn medium color="black" dark outlined class="mb-2 ms-2" style="max-width: 20px; border-color: #dddfe1;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="#9ea1a5" dark @click="openCreateDIalog()">
                    mdi-plus
                  </v-icon>
                </template>
                <span>הוסף חדש</span>
              </v-tooltip>
            </v-btn>
            <v-btn medium color="black" outlined dark class="mb-2 ms-2" style=" border-color: #dddfe1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="#9ea1a5" dark @click="downloadExcel()">
                    mdi-file-download
                  </v-icon>
                </template>
                <span>הורד לקובץ אקסל</span>
              </v-tooltip>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols='auto' lg='6' v-if="$vuetify.breakpoint.mdAndUp">
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">

      <v-data-table id="table1" :headers="headers" :items="users" :search="search" sort-by="index"
        :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }"
        style="background-color: #f3f6f9;">

        <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }">
          <span class="table-headers">{{ header.text }}</span>
        </template>

        <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
          <tr class="table-rows">
            <td class="table-cells">{{ index + 1 }}</td>
            <td class="table-cells">{{ item.name }}</td>
            <td class="table-cells">{{ item.nameInvoice }}</td>
            <td class="table-cells">{{ item.address }}</td>
            <td class="table-cells">{{ item.phone }}</td>
            <td class="table-cells">{{ item.email }}</td>
            <td class="table-cells">{{ item.role }}</td>
            <td class="table-cells">{{ item.permission }}</td>
            <td class="table-cells">{{ item.api_token }}</td>
            <td class="table-cells">
              <v-switch color="#0099cd" v-model="item.status" @click="enableOrdisable(item.status, item.id)"
                :label="item.status == 1 ? 'פעיל' : 'מושבת'">
              </v-switch>
            </td>
            <td class="table-cells">{{ item.balance }}</td>
            <td class="table-cells" style="color: #abafb3 !important">{{ item.created_at }}</td>
            <td class="table-cells" style="color: #abafb3 !important">{{ item.modified_at }}</td>
            <td class="table-cells">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#fe3153" width="106" max-width="100%" height="37" v-bind="attrs" v-on="on" rounded
                    class="mx-auto">
                    <span class="white--text cancle-button" style="">פעולות</span></v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="action in actionMenu" :key="action.id"
                    v-if="!(action.id === 'switch' && item.role === 'admin')"
                    @click="action.id === 'edit' ? openEditDialog(item, index) : action.id === 'delete' ? openDeleteDialog(item, index) : switchToSeller(item, index)">
                    <v-list-item-icon>
                      <v-icon>{{ action.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-row v-if="progressShow" class="my-5">
            <v-col class="d-flex justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
          <div class="ma-5">
            <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין נתונים
              למנוי זה</span>
          </div>
          <v-btn color="#0099cd" dark style="margin-bottom: 10px;" @click="refreshDetails()">
            Reset
          </v-btn>
        </template>

      </v-data-table>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      @userAdded="newUserSaved" @userEdited="editedUserSaved"> </AddEditDialog>

    <Alert-dialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle" :itemIndex="itemIndex"
      :item="itemToDelete" @userDeleted="userDeleted"> </Alert-dialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import TableToExcel from "@linways/table-to-excel";
import AddEditDialog from '@/components/admin/usersPage/dialogs/AddEditUserDialog'
import AlertDialog from '@/components/admin/usersPage/dialogs/AlertDialog'
import utils from '@/util/utils'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar
  },
  data: () => ({
    isResponsive: false, // Initially set to false
    headers: [
      { text: '#', value: "index", sortable: false },
      {
        text: 'שם',
        align: 'start',
        value: 'name',
      },
      { text: 'שם לחשבונית', value: 'nameInvoice' },
      { text: 'כתובת', value: 'address' },
      { text: 'טלפון', value: 'phone' },
      { text: 'אימייל', value: 'email' },
      { text: 'קבוצה', value: 'role' },
      { text: 'הרשאות', value: 'permission' },
      { text: 'api_token', value: 'api_token' },
      { text: 'סטטוס', value: 'status' },
      { text: 'יתרה לניצול בש"ח', value: 'balance' },
      { text: 'נוצר בתאריך', value: 'created_at' },
      { text: 'עודכן בתאריך', value: 'modified_at' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    actionMenu: [
      { id: "switch", title: "היכנס למשווק", show: true, icon: "mdi-login-variant" },
      { id: "edit", title: "ערוך רשומה", show: true, icon: "mdi-pencil" },
      { id: "delete", title: "מחק רשומה", show: true, icon: "mdi-delete" },
    ],
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    users: [{}],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemIndex: 0,
    itemToDelete: {},
    isAuth: false,
    userName: "",
    email: "",
    search: "",

  }),

  methods: {

    async getUserDetails() {
      try {
        this.users = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/admin/users_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.users = jsonObject;
          this.translateToHebrewAndDates();
          this.progressShow = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get dates list: " + error, "red");
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    translateToHebrewAndDates() {
      this.users = this.users.map((user) => {
        return {
          ...user,
          permission: user.permission === "edit" ? "עריכה" : user.permission === "view" ? "צפייה" : user.permission,
          created_at: utils.getCurrentDate(user.created_at),
          modified_at: utils.getCurrentDate(user.modified_at),
        }
      });
    },
    async enableOrdisable(statusChanged, id) {

      let message = statusChanged ? "הסוכן הופעל בהצלחה" : "הסוכן הושהה בהצלחה";
      let editAmutastatus = JSON.stringify({ id: id, status: statusChanged })
      let token = localStorage.getItem("token");

      try {
        let api = process.env.VUE_APP_BASE_URL + "/admin/change_user_status";
        const res = await fetch(api, ApiServices.requestOptions("PUT", editAmutastatus, token));
        if (res.status === 400) {
          this.showSnackBar("שגיאה בשינוי סטטוס ", "red");
        } else if (res.status === 403) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה בשינוי סטטוס " + jsonObject.message, "red");
          this.openMode = false;
        } else if (res.status === 200) {
          this.showSnackBar(message, "green");
        }
      } catch (error) {
        this.showSnackBar("Error change user status: " + error, "red");
      }
    },

    downloadExcel() {
      // Create a copy of the headers with English text
      const englishHeaders = [
        { text: '#', value: "index", sortable: false },
        { text: 'name', align: 'start', value: 'name', },
        { text: 'nameInvoice', value: 'nameInvoice' },
        { text: 'address', value: 'address' },
        { text: 'phone', value: 'phone' },
        { text: 'email', value: 'email' },
        { text: 'role', value: 'role' },
        { text: 'permission', value: 'permission' },
        { text: 'api_token', value: 'api_token' },
        { text: 'status', value: 'status' },
        { text: 'balance', value: 'balance' },
        { text: 'created_at', value: 'created_at' },
        { text: 'modified_at', value: 'modified_at' },
        { text: 'פעולות', value: 'actions', sortable: false },
      ];
      // Clone the table
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);
      // Replace the table headers with the English headers in the cloned table
      const tableHeaders = clonedTable.getElementsByTagName('th');
      for (let i = 0; i < englishHeaders.length; i++) {
        tableHeaders[i].textContent = englishHeaders[i].text;
      }
      // Remove the first header
      tableHeaders[0].parentNode.removeChild(tableHeaders[0]);
      // Remove the last header
      tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);
      // Remove the first and last columns from the cloned table
      const tableRows = clonedTable.getElementsByTagName('tr');
      for (let i = 0; i < tableRows.length; i++) {
        const tableCells = tableRows[i].getElementsByTagName('td');
        if (tableCells.length > 0) {
          tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell
          tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
        }
      }
      // Download the Excel file from the cloned table
      TableToExcel.convert(clonedTable, {
        name: 'usersList.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });
    },
    refreshPage() {
      location.reload();
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך משתמש"
      this.editedItem = item
      this.editedItem.itemIndex = index;
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemIndex = index;
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את המשתמש?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף משתמש"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newUserSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.users.push(item);

    },
    editedUserSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      let g = Object.assign(this.users[item.itemIndex], item);

    },
    userDeleted(itemIndex, text, color) {
      if (itemIndex === "") {
        this.showSnackBar(text, color)
        return
      }
      this.users.splice(itemIndex, 1);
      this.showSnackBar(text, color)
    },
    async switchToSeller(item, index) {

      this.progressShow = true
      let token = localStorage.getItem("token");
      //save admin token
      localStorage.setItem('token2', token);
      let MyJSON = JSON.stringify({ id: item.id });
      let api = process.env.VUE_APP_BASE_URL + "/admin/swich_to_user"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
        this.progressShow = false;
        const jsonObject = await response.json();
        if (response.status === 400) {
          this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
        } else if (response.status === 200) {
          //save user token
          localStorage.setItem('token', jsonObject.token);
          //set user balance
          let balance = Auth.getUserBalance();
          localStorage.setItem('balance', balance);
          this.$router.replace({ name: "seller" })
        }
      } catch (error) {
        this.showSnackBar("Error Login: " + error, "red");
      }
    },
    // watchSellerReport(item, index) {

    //    this.$router.push({ name: "admin_seller_reports", params: { id: item.id, name: item.name } })
    // },
  },
  mounted() {
    this.getUserDetails();
  },
}
</script>
<style scoped>
.header1 {
  background-color: #fff;
  height: 89px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.header2 {
  min-height: 89px;
  display: flex;
}

.search {
  width: 332px !important;
  height: 49px;
  max-width: 100% !important;
  padding: 10px !important;
  padding-right: 10px !important;
  /* Space for the search icon */
  border: 1px solid #dddfe1 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  font-family: "circular-std-book" !important;
}

.table-rows {
  background-color: #FFFFFF !important;
  height: 89px !important;
  box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
  border-radius: 3px !important;
}

.table-headers {
  font-family: 'Circular-std-book' !important;
  font-weight: 100 !important;
  font-size: 14px;
  /* Adjust this value as needed */
}

.table-cells {
  font-family: 'Circular-std-book' !important;
  font-size: 16px !important;
  color: #6a707e;
}

.cancle-button {
  font-size: 16px;
  font-family: 'Circular-std-book' !important;
}

@media (max-width: 1024px) {
  .table-headers {
    font-family: 'Circular-std-book' !important;
    font-weight: 100 !important;
    font-size: 13px;
    /* Adjust this value as needed */
  }
}

@media (min-width: 1024px) {
  .table-headers {
    font-family: 'Circular-std-book' !important;
    font-weight: 100 !important;
    font-size: 15px;
  }
}

@media (min-width: 2000px) {
  .table-headers {
    font-family: 'Circular-std-book' !important;
    font-weight: 100 !important;
    font-size: 18px;
  }
}
</style>
  