<template>
    <div class="chart-container">
        <div class="chart-wrapper">
            <canvas id="myChartDay" class="responsive"></canvas>
        </div>
    </div>
</template>
     
<script>
import Chart from 'chart.js/auto';

export default {
    props: {
        dataDays: Array,
    },
    data: () => ({
        chartInstance: null // Keep track of the chart instance
    }),
    methods: {
        createChart() {
            const ctx = document.getElementById('myChartDay');

            this.chartInstance = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.labelsByDays,
                    datasets: [{
                        label: 'Phones activates per day',
                        data: this.dataDays,
                        borderWidth: 1,
                        backgroundColor: ['#6a6aec80']
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        },
    },
    computed: {
        labelsByDays() {
            let labels = [];
            if (this.dataDays.length > 1) {
                for (let i = 1; i <= this.dataDays.length; i++) {
                    labels.push("Day" + i);
                }
            } else {
                for (let i = 1; i <= 30; i++) {
                    labels.push("Day" + i);
                }
            }
            return labels;
        }
    },
    watch: {
        dataDays: {
            handler(newData) {

                if (this.chartInstance) {
                    // Update the chart data
                    this.chartInstance.data.labels = this.labelsByDays;
                    this.chartInstance.data.datasets[0].data = newData;
                    this.chartInstance.update();
                }
            },
            immediate: true // Update the chart when the component is mounted
        }
    },
    mounted() {
        // console.log(this.dataDays.length);
        this.createChart();

    },
}
</script>
     
<style>
.chart-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.chart-wrapper {
    background-color: white;
    padding: 10px;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.2); /* Adjust the values as needed */
}

.responsive {
    width: 100%;
    height: 300px;
}

@media (min-width: 568px) {
    .responsive {
        height: 500px;
    }
}
</style>