<template>
  <v-app style="background-color: #f3f6f9">
    <v-main>
      <nav-bar></nav-bar>
      <CodesList />
    </v-main>
  </v-app>
</template>
<script>
import NavBar from '@/components/widgets/navBar.vue'
import CodesList from '@/components/admin/codesPage/CodesList.vue'
import Auth from '@/services/auth.service.js'


export default {
  components: {
    NavBar,
    CodesList
  },
  mounted() {
    document.title = 'Codes | ISRASIM'

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
  