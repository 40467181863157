<template>
   <v-app style="background-color: #f3f6f9">
    <v-main>
    <nav-bar></nav-bar>
    <SellersList />
</v-main>
  </v-app>
</template>
<script>
import NavBar from '@/components/widgets/navBar.vue'
import SellersList from '@/components/admin/usersPage/SellersList.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    NavBar,
    SellersList
  },
  mounted() {
    document.title = 'Users | ISRASIM'

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
  