<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" outlined dense label="שם"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.nameInvoice" outlined dense
                                    label="שם לחשבונית"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.address" outlined dense label="כתובת"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" outlined dense label="טלפון"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.email" outlined dense label="אימייל"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'הוסף משתמש'">
                                <v-text-field v-model="itemEdited.password" outlined dense label="סיסמה"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="roleList" outlined dense v-model="itemEdited.role" label="קבוצה"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="itemEdited.role === 'admin'">
                                <v-select :items="permissionList" outlined dense v-model="itemEdited.permission"
                                    label="הרשאות" :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="itemEdited.role === 'seller'">
                                <v-select :items="plans" outlined dense v-model="itemEdited.plans" :item-text="'name'"
                                    :item-value="'id'" multiple label="חבילות" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.balance" outlined dense maxlength="6"
                                    :rules="[v => !!v || 'שדה חובה']" required label='יתרה לניצול בש"ח'
                                    @keypress="validateNumericInput"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="itemEdited.role === 'seller'">
                                <v-switch :items="plans" dense v-model="itemEdited.only_white_list"
                                    label="רק מרשימה לבנה"></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#6a6aec" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#6a6aec" text @click="formTitle === 'ערוך משתמש' ? saveEditedUser() : saveNewUser()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="4000">
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import SharedFunc from '@/shared/sharedFunc'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },

    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            id: "",
            name: "",
            nameInvoice: "",
            address: "",
            phone: "",
            email: "",
            password: "",
            role: "seller",
            permission: "עריכה",
            balance: "10",
            plans: [],
            itemIndex: "",
            only_white_list: false
        },
        permissionList: ["צפייה", "עריכה"],
        roleList: ["admin", "seller"],
        plans: [1, 2]
    }),

    methods: {
        async saveNewUser() {
            console.log(this.itemEdited);
            if (this.$refs.form.validate()) {
                if (this.itemEdited.role === "seller") {
                    this.itemEdited.permission = "עריכה"
                }
                const datesJSON = JSON.stringify(this.itemEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/create_user";
                    const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
                    this.progressShow = false;
                    if (res.status === 400) {
                        const jsonObject = await res.json();
                        this.$emit("userAdded", "", " :שגיאה בהוספת משתמש" + jsonObject.details.errors[0].message, "red");
                    } else if (res.status === 403) {
                        const jsonObject = await res.json();
                        this.$emit("userAdded", "", " :שגיאה בהוספת משתמש" + jsonObject.message, "red");
                        this.openMode = false;
                    } else if (res.status === 200) {
                        this.$emit("userAdded", this.itemEdited, "הרשומה נוספה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved user: " + error, "red");
                    this.$emit("userAdded", "", "Error saved user: " + error, "red");
                }
            }
        },
        async saveEditedUser() {
            if (this.$refs.form.validate()) {
                if (this.itemEdited.role === "seller") {
                    this.itemEdited.permission = "עריכה"
                }
                const datesJSON = JSON.stringify(this.itemEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/update_user";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
                    this.progressShow = false;
                    if (res.status === 400) {
                        const jsonObject = await res.json();
                        this.$emit("userEdited", "", "error: " + jsonObject.details.errors[0].message, "red");
                    } else if (res.status === 403) {
                        const jsonObject = await res.json();
                        this.$emit("userEdited", "", " :שגיאה בעריכת משתמש" + jsonObject.message, "red");
                        this.openMode = false;
                    } else if (res.status === 200) {
                        this.$emit("userEdited", this.itemEdited, "הרשומה עודכנה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved list: " + error, "red");
                    this.$emit("userEdited", "", "Error edit date: " + error, "red");

                }
            }
        },
        async getPlans() {
            this.plans = await SharedFunc.getPlans();
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        validateNumericInput(event) {
            const charCode = event.charCode;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.getPlans();
        if (this.formTitle === "ערוך משתמש") {
            this.itemEdited = { ...this.itemToEdit };
            if (typeof (this.itemToEdit.plans) === "string") {
                this.itemEdited.plans = JSON.parse(this.itemToEdit.plans);
            }
        }
    },
}
</script>
<style></style>
  