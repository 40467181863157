<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{ formTitle }}</v-card-title>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#6a6aec" text @click="closeDelete">ביטול</v-btn>
        <v-btn color="#6a6aec" text @click="actionConfirmed">אישור</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>
import ApiServices from '@/services/api.service'

export default {
  props: {
    item: Object,
    formTitle: String,
    value: { type: Boolean, default: false },

  },
  data: () => ({
    progressShow: false,
    itemEdited: {}
  }),

  methods: {
    actionConfirmed() {
      if (this.formTitle === "האם אתה בטוח שברצונך לאשר את הבקשה?") {
        this.updateRefund("confirmed");
      } else {
        this.updateRefund("rejected");
      }
    },

    async updateRefund(type) {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const refundJSON = JSON.stringify(this.itemEdited);
      
        let api;
        if (type === "confirmed") {
          this.itemEdited.status = "בוטל";
          api = process.env.VUE_APP_BASE_URL + "/admin/confirm_refund";
        } else {
          this.itemEdited.status = "הצליח"
          api = process.env.VUE_APP_BASE_URL + "/admin/reject_refund";
        }

        const res = await fetch(api, ApiServices.requestOptions("POST", refundJSON, token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.$emit("refundsUpdated", "", "שגיאה בעדכון בקשה " + jsonObject.message, "red");
        } else if (res.status === 403) {
          this.$emit("refundsUpdated", "", " :שגיאה בעדכון בקשה" + jsonObject.message, "red");
        } else if (res.status === 200) {
          if (type === "confirmed") {
            this.$emit("refundsUpdated", this.itemEdited, "הבקשה אושרה בהצלחה!", "green");
          }else{
            this.$emit("refundsUpdated", this.itemEdited, "הבקשה נדחתה בהצלחה!", "green");
          }
          this.openMode = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.$emit("refundsUpdated", "", "תקלה בעדכון בקשה" + error, "red");
      }
    },

    closeDelete() {
      this.openMode = false;
    }
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  mounted() {
    this.itemEdited = { ...this.item};
    console.log(this.itemEdited);
  },
}
</script>
    
      