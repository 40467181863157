<template >
 
  <v-container style="margin-top: 70px; margin-bottom: 70px;">

    <v-row class="d-flex justify-center" no-gutters>
      <v-col cols='12' no-gutters>
        <div class="header1">
          <span class="text-center" style="font-weight: bold; font-size: 22px; padding-bottom: 10px;">דוח
            טעינות</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols='auto' lg='4'>
        <div class="header2">

          <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
            class="search">
          </v-text-field>
          <div class="d-flex flex-wrap pa-3 ">
            <v-btn medium color="black" outlined dark class="mb-2 ms-2" style=" border-color: #dddfe1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="#9ea1a5" dark @click="downloadExcel()">
                    mdi-file-download
                  </v-icon>
                </template>
                <span>הורד לקובץ אקסל</span>
              </v-tooltip>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols='auto' lg='4' v-if="$vuetify.breakpoint.mdAndUp">
      </v-col>
      <v-col cols='auto' lg='4'>
        <div>
          <v-row justify="center">
            <v-col cols="6" md="5">
              <v-text-field v-model="startDate" label="הצג מתאריך" hide-details class="search-date">
                <template v-slot:prepend-inner>
                  <date-picker v-model="startDate" />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" md="5">
              <v-text-field v-model="endDate" label="עד תאריך" hide-details class="search-date">
                <template v-slot:prepend-inner>
                  <date-picker v-model="endDate" />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">

      <v-data-table id="table1" :headers="headers" :items="filteredData" :search="search" sort-by="index"
        :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }"
        style="background-color: #f3f6f9;">

        <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }">
          <span class="table-headers">{{ header.text }}</span>
        </template>

        <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
          <tr class="table-rows">
            <td class="table-cells">
              {{ index + 1 }}
            </td>
            <td class="table-cells">{{ item.mobile_activated }}</td>
            <td class="table-cells" style="color: #0099cd"
              :class="[item.status === 'בוטל' ? 'header-cell-red' : '', item.status === 'בבדיקה' ? 'header-cell-orange' : '']">
              {{ item.status }}</td>
            <td class="table-cells" style="color: #abafb3 !important">{{ item.created_at }}</td>
            <td>
              <v-btn @click="openCancleDialog(item, index)"
                v-if="(item.status !== 'בוטל' && item.status !== 'בבדיקה') && isCancellable(item.created_at)"
                color="#fe3153" width="106" max-width="100%" height="37" rounded class="mx-auto">
                <span class="white--text cancle-button" style="">ביטול</span>
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-row v-if="progressShow" class="my-5">
            <v-col class="d-flex justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
          <div class="ma-5">
            <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין נתונים
              למנוי זה</span>
          </div>
          <v-btn color="#0099cd" dark @click="getSellerReports()" style="margin-bottom: 10px;">
            Reset
          </v-btn>
        </template>

      </v-data-table>

      <v-btn @click="getSellerReports()" color="#0099cd" width="143" max-width="100%" height="35" rounded
        style="margin-top: 5px; margin-bottom: 5px;" class="mx-auto">
        <span class="white--text" style="font-size: 16px; ">Resset</span>
      </v-btn>
    </v-row>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :itemIndex="itemIndex" :item="itemToCancle"
      @itemCanceled="itemCanceled"> </AlertDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>

</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import TableToExcel from "@linways/table-to-excel";
import DatePicker from "@/components/widgets/DatePicker";
import AlertDialog from '@/components/seller/dialogs/AlertDialog'
import utils from '@/util/utils'

export default {
  components: {
    SnackBar,
    DatePicker,
    AlertDialog,
  },
  data: () => ({
    isResponsive: false, // Initially set to false
    headers: [
      { text: "#מס'", value: "index", sortable: false },
      { text: 'טלפון שהופעל', value: 'mobile_activated' },
      { text: 'סטטוס', value: 'status', class: 'header-cell', },
      { text: 'נוצר בתאריך', value: 'created_at' },
      { text: 'פעולות', value: 'actions', sortable: false },
    ],
    reports: [{}],
    progressShow: false,
    alertDialog: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    cancledItem: {},
    itemIndex: 0,
    itemToCancle: {},
    isAuth: false,
    userName: "",
    email: "",
    search: "",
    endDate: null,
    startDate: null,
  }),
  computed: {
    filteredData() {
      if (!this.startDate || !this.endDate) {
        return this.reports;
      }
      return this.reports.filter((item) => {
        const itemDate = new Date(item.created_at);
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);

        return itemDate >= start && itemDate <= end;
      });
    },

  },
  methods: {
    isCancellable(created_at) {
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      const createdAtDate = this.convertStringDateToObj(created_at);
      return createdAtDate >= twentyFourHoursAgo;
    },
    convertStringDateToObj(dateString) {
      if (!dateString) {
        return null;
      }
      let [datePart, timePart] = dateString.split(",");
      // Split the date part into day, month, and year
      let [day, month, year] = datePart.split("/");
      // Rearrange the date parts into the expected format
      let modifiedDateString = `${month}/${day}/${year} ${timePart}`;
      // Create the Date object
      return new Date(modifiedDateString);
    },
    async getSellerReports() {
      try {
        this.reports = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/seller/reports_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.reports = jsonObject;
          this.translateToHebrewAndDates();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get dates list: " + error, "red");
      }
    },
    openCancleDialog(item, index) {
      this.itemToCancle = item
      this.itemToCancle.itemIndex = index;
      this.alertDialog = true
    },
    itemCanceled(itemIndex, text, color) {
      if (itemIndex === "") {
        this.showSnackBar(text, color)
        return
      }
      this.showSnackBar(text, color)
      this.getSellerReports();
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    translateToHebrewAndDates() {
      this.reports = this.reports.map((report) => {
        return {
          ...report,
          status: report.status === "success" ? "הצליח" : report.status === "canceled" ? "בוטל" : report.status === "in progress" ? "בבדיקה" : report.status,
          created_at: utils.getCurrentDate(report.created_at),
          // created_at: new Date(report.created_at).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }),
        }
      });
    },
    downloadExcel() {

      // Download the Excel file from the cloned table
      TableToExcel.convert(document.getElementById("table1"), {
        name: 'reportsList.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });
    },
  },
  mounted() {
    this.getSellerReports();
  },
}
</script>
<style>

.header1 {
  background-color: #fff;
  height: 89px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.header2 {
  min-height: 89px;
  display: flex;
}

.divider {
  border-top: 0.5px solid #cfcccc;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0.4693033993244171px 2.963064908981323
}

.fields-input {
  /* max-width: 200px; */
  margin-right: 30px;
  margin-left: 30px;

}

.custom-card {
  margin-bottom: 0;
  /* Remove bottom margin */
}

.header-cell-red {
  color: rgb(202, 4, 4) !important;
}

.header-cell-orange {
  color: rgb(214, 141, 4) !important;
}

.search {
  width: 332px !important;
  height: 49px;
  max-width: 100% !important;
  padding: 10px !important;
  padding-right: 10px !important;
  /* Space for the search icon */
  border: 1px solid #dddfe1 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  font-family: "circular-std-book" !important;
}

/* remove underline from textfield  */
.v-text-field>.v-input__control>.v-input__slot:before {
  width: 0px !important
}

table {
  border-collapse: separate !important;
  border-spacing: 0px 10px !important;
}

.search-date {
  height: 49px;
  padding: 10px !important;
  padding-right: 10px !important;
  /* Space for the search icon */
  border: 1px solid #dddfe1 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  font-family: "circular-std-book" !important;
}

/* .table-headers {
  font-family: 'Circular-std-book' !important;
  font-size: 18px;
  font-weight: 100 !important;
} */

.table-rows {
  background-color: #FFFFFF !important;
  height: 89px !important;
  box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
  border-radius: 3px !important;
  /* border-collapse: separate !important; */
  /* NOTE: syntax is <horizontal value> <vertical value> */
}

/* .v-data-table>.v-data-table__wrapper>table {
  border-spacing: 0 60px !important;
  margin-bottom: 0 60px !important;
} */

.table-cells {
  font-family: 'Circular-std-book' !important;
  font-size: 16px !important;
  color: #6a707e;
}

.cancle-button {
  font-size: 16px;
  font-family: 'Circular-std-book' !important;
}

</style>
